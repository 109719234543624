import React, { useEffect, useState } from 'react'
import { LanguageSelectorStyles, ResponsiveSelect } from './styles'
import languageItems from '../../languagemenuitems/languageitems.json'
import useShellRootContext from '../../contexts/ShellRoot/useShellRootContext'

const LanguageSelector = () => {
  const { interfaces } = useShellRootContext()
  const localize = interfaces?.v1.localization
  const [languages, setLanguages] = useState([])

  const getFeatureFlag = async () => {
    const client = await interfaces?.v1?.featureFlags?.getClient('ecp')
    const featureFlag = await client.getFeatureFlag({
      key: 'ecp-locale-codes',
      defaultValue: ['en-US']
    })
    setLanguages(featureFlag as Array<string>)
  }
  const languageFlags = languageItems.filter((item) =>
    languages.includes(item.value)
  )

  const handleLocale = () => {
    if (localize.locale) {
      return [localize.locale]
    } else {
      return ['en-US']
    }
  }
  const [langText, setLangText] = useState(handleLocale)
  const handleLanguageChange = (value) => {
    localize.setLocale({ language: value.language, country: value.country })
    setLangText([localize.locale])
  }
  useEffect(() => {
    getFeatureFlag()
  })
  return (
    <>
      <LanguageSelectorStyles>
        <ResponsiveSelect
          data-testid="language-selector-select-box"
          options={languageFlags}
          clearIcon={false}
          value={langText}
          visibleOptions={7}
          placement="top"
          onChange={handleLanguageChange}
        ></ResponsiveSelect>
      </LanguageSelectorStyles>
    </>
  )
}
export default LanguageSelector
