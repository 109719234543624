import { DirectionProvider } from '@veneer/core'
import { ThemeProvider } from '@veneer/theme/'
import React, { useEffect, useMemo, useState } from 'react'
import App from '../src/App'
import resources from 'src/assets/locale'
import { ShellRootProvider } from 'src/contexts/ShellRoot'
import {
  InterfacesType,
  ShellRootComponentProps,
  TranslatorFunctionType
} from 'src/types/shell'
import projectNames from './configs/projectNames'

declare global {
  interface Window {
    Shell: InterfacesType
  }
}

export default function Root({
  // used only to test onboarding services
  useToast,
  properties
}: ShellRootComponentProps) {
  const [t, setT] = useState<TranslatorFunctionType>()
  const { v1, v2 } = window.Shell
  const interfaces = {
    v1,
    v2
  }

  const directionValue = v1?.localization?.useReactGetLanguageDirection?.(React)

  const { themeProviderProps, userThemeMode } = useMemo(() => {
    return {
      themeProviderProps: v1?.theme?.getThemeProviderProperties?.(),
      userThemeMode: v1?.theme?.getUserThemeMode?.()
    }
  }, [v1?.theme])

  // Create a translator function by providing the resource files
  useEffect(() => {
    v1.localization
      .createTranslatorFunction(resources)
      .then((v) => setT(() => v))
  }, [v1])

  // Wait for the translator function creation to show the content
  if (!t) {
    return null
  }

  return (
    <section id={projectNames.packageJsonName}>
      <DirectionProvider direction={directionValue}>
        <ThemeProvider
          {...themeProviderProps}
          mode={userThemeMode}
        >
          <ShellRootProvider {...{ interfaces, t, useToast, properties }}>
            <App />
          </ShellRootProvider>
        </ThemeProvider>
      </DirectionProvider>
    </section>
  )
}
