import styled from 'styled-components'
import { Select } from '@veneer/core'
export const LanguageSelectorStyles = styled.div`
  width: 232px;
`
export const ResponsiveSelect = styled(Select)`
  #vn-select-1 {
    align-items: center;
  }
`
